<template>
  <div class="annex">
    <el-card>
      <div slot="header" class="annex-text">
        <span class="clearfix">项目关联合同信息</span>
      </div>
      <el-table :data="tableData" border style="width: 100%">
        <el-table-column type="index" align="center" label="序号" width="50"> </el-table-column>
        <el-table-column prop="agreementName" min-width="320" align="center" label="合同名称">
        </el-table-column>
        <el-table-column prop="agreementType" align="center" label="合同类型">
          <template slot-scope="scope">
            {{ scope.row.agreementType | dict(dictData.agreementType) }}
          </template>
        </el-table-column>
        <!-- <el-table-column
          prop="agreementCategory"
          align="center"
          label="合同种类"
        >
         {{ scope.row.agreementCategory | dict(dictData.agreementCategory) }}
        </el-table-column> -->
        <el-table-column prop="signedDate" align="center" min-width="110" label="签订合同时间">
          <template slot-scope="scope"> {{ scope.row.signedDate | dateFormat }}</template>
        </el-table-column>
        <el-table-column prop="actualContractAmount" align="center" label="合同金额">
          <template slot-scope="scope">
            {{ scope.row.actualContractAmount | thousands }}
          </template>
        </el-table-column>
        <el-table-column prop="actualAmount" align="center" label="实际金额">
          <template slot-scope="scope">
            {{ scope.row.actualAmount | thousands }}
          </template>
        </el-table-column>
        <el-table-column label="操作" width="160" align="center">
          <template slot-scope="scope">
            <el-button size="mini" type="primary" @click.stop="viewerContract(scope.row)"
              >详情</el-button
            >
            <el-button size="mini" type="success" @click="leaveFor(scope.row)">前往</el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-descriptions title="" :column="1" border style="float: right">
        <el-descriptions-item label="合计" contentStyle="width:200px" labelStyle="width:90px">
          {{ amountAll | thousands }}
        </el-descriptions-item>
      </el-descriptions>
    </el-card>
    <EditDialog
      width="80%"
      :isReturn="true"
      @closeFn="isAgreemen = false"
      :isShow.sync="isAgreemen"
    >
      <div slot="title" class="title">合同信息</div>
      <div slot="content" class="content" style="min-height: 500px">
        <ContractParticulars :form="agreemenOptions" />
      </div>
      <div slot="footer" class="footer">
        <el-button type="primary" plain @click="isAgreemen = false">返回</el-button>
      </div>
    </EditDialog>
  </div>
</template>
<script>
import { initTreeData } from '@/util/common'
import { mapGetters, mapState } from 'vuex'
import { number } from 'echarts'
export default {
  components: {
    EditDialog: () => import('@/components/EditDialog.vue'),
    ContractParticulars: () => import('./contract-particulars.vue'),
  },
  props: {
    options: {
      type: Object,
      default: function () {
        return {}
      },
    },
  },
  data() {
    return {
      tableData: [],
      dictData: {
        agreementType: [],
        agreementCategory: [],
      },
      isAgreemen: false,
      agreemenOptions: {},
    }
  },
  inject: ['refresh'],
  computed: {
    ...mapState({
      originalForm: state => state.project.originalForm,
    }),
    amountAll() {
      return this.tableData.reduce((total, v) => total + Number(v.actualContractAmount), 0)
    },
  },
  created() {
    this.$api.dict
      .listSysDictData('TYPE_CONTRACT', true)
      .then(res => {
        this.dictData.agreementType = initTreeData(res.data)
      })
      .catch(err => {
        console.log(err)
      })
  },
  filters: {
    thousands(a) {
      if (a == null || a == '' || a == undefined) {
        return 0
      } else {
        a = Number(a).toLocaleString()
        return a
      }
    },
  },
  watch: {
    originalForm: {
      immediate: true, //初始化立即执行
      deep: true, //对象深度监测
      handler: function (newVal) {
        if (newVal.agreementList) {
          this.tableData = newVal.agreementList.deepClone()
        }
      },
    },
  },
  methods: {
    // 前往合同
    async leaveFor(row) {
      const res = await this.$api.agreement.list({ id: row.id })
      if (res.data) {
        const obj = {
          operatingButton: res.data.records[0].operatingButton,
          id: res.data.records[0].id,
          status: res.data.records[0].status,
        }
        const { href } = this.$router.resolve({
          path: '/agreement/contractList',
          query: {
            data: JSON.stringify(obj),
          },
        })
        window.open(href, '_blank')
      }
    },
    // 查看合同详情
    viewerContract(row) {
      this.$api.agreement
        .selectDetailById(row.id)
        .then(res => {
          this.$store.commit({
            type: 'project/SET_ORIGINALFORM',
            originalForm: res.data.deepClone(),
          })
          this.agreemenOptions = res.data.deepClone()
        })
        .catch(err => {
          console.log(err)
        })
      this.isAgreemen = true
    },
  },
  beforeDestroy() {},
}
</script>
<style scoped lang="scss">
@import '@/styles/config.scss';
.annex {
  /deep/.el-card {
    //width:50%;
    .annex-text {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    + .el-card {
      margin-top: 20px;
    }
    .el-card__header {
      padding: 10px 15px;
      font-size: 16px;
      font-weight: bold;
    }
    .el-table {
      height: auto;
    }
  }
}
</style>
